.LostItem_Container {
  margin: 2% 0 2% 10%;
}

.LostItem_BodyDiv {
  width: 60%;
  display: flex;
  justify-content: space-between;
  padding: 2% 0;
}

.LostItem_ImageDiv {
  width: 45%;
}

.LostItem_UpperImageDiv {
  width: 100%;
}

.LostItem_UpperImageDiv img {
  width: 100%;
}

.LostItem_LowerImageDiv {
  padding: 3% 0;
  width: 100%;
  display: flex;
  margin-top: 10px;
  /* justify-content: space-between; */
}

.LostItem_LowerImageDiv img {
  width: 32%;
  border-radius: 10px;
  margin-right: 1.3%;
}

.LostItem_TextDiv {
  width: 45%;
  height: min-content;
}

.LostItem_TitleDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
}

.LostItem_TitleDiv p {
  color: #0c80a0;
  font-weight: 600;
}

.LostItem_DetailDiv {
  line-height: 50%;
}

.LostItem_Manufacturer {
  margin-bottom: 5%;
}

.LostItem_Description {
  line-height: 120%;
  font-weight: 400;
}

.LostItem_Divider {
  height: 2px;
  background: #dddddd;
  margin: 2% 0;
}

.LostItem_HistoryDiv {
  line-height: 50%;
  padding-top: 2%;
  height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.LostItem_History_H {
  margin-bottom: 5%;
  font-size: 1.1rem;
}

.LostItem_History_P {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.location_div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.locaion_p {
  width: 50%;
  line-height: 1;
  text-align: right;
}

.LostItem_BtnDiv {
  text-align: center;
}

.LostItem_BtnDiv p {
  margin: 5% 0;
}

.LostItem_BtnDiv p span {
  color: #831212;
  font-weight: 700;
}

.LostItem_FoundBtn {
  background: #0a3f74;
  border-radius: 100px;
  padding: 1% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3% 1%;
}

.LostItem_ChatBtn {
  border-radius: 100px;
  padding: 1% 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3% 1%;
  border: 2px solid #0a3f74;
}

.CookieBanner_Div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dddddd;
  padding: 10px;
}

.CookieBanner_TypoDiv {
  width: 70%;
}

.CookieBanner_desp {
  color: #000000;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1;
  margin: 0;
}

.CookieBanner_BtnDiv {
  width: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CookieBanner_AgreeBtn {
  margin-right: 2%;
  width: 50%;
  padding: 3%;
  background: #003b7e;
  font-weight: 700;
  color: white;
  text-transform: capitalize;
  border-radius: 10px;
  border: 2px solid #003b7e;
}

.CookieBanner_DeclineBtn {
  padding: 3%;
  width: 50%;
  font-weight: 700;
  text-transform: capitalize;
  border: 2px solid #003b7e;
  color: #003b7e;
  border-radius: 10px;
}

@media only screen and (max-width: 1366px) {
  .LostItem_BodyDiv {
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .LostItem_BodyDiv {
    width: 100%;
    flex-direction: column;
  }

  .LostItem_ImageDiv {
    width: 60%;
  }

  .LostItem_TextDiv {
    margin: 5% 0;
    width: 90%;
  }
}

@media only screen and (max-width: 900px) {
  .LostItem_ImageDiv {
    width: 90%;
  }
}

@media only screen and (max-width: 600px) {
  .CookieBanner_BtnDiv {
    width: 50%;
    margin-top: 10px;
    font-size: 11px;
  }

  .CookieBanner_AgreeBtn,
  .CookieBanner_DeclineBtn {
    padding: 2%;
  }

  .CookieBanner_Div {
    flex-direction: column;
  }

  .CookieBanner_desp {
    font-size: 0.6rem;
  }

  .CookieBanner_TypoDiv {
    width: auto;
  }
}

@media only screen and (max-width:340px) {
  .LostItem_HistoryDiv {
    line-height: normal;
  }

}