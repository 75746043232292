.all_fields{
    display: flex;
    flex-direction: column;
}
.input_field{
    padding: 10px;
    border-radius: 5px;
    outline: none;
    margin-bottom: 10px;
    border: 1px solid;
}
.input_field{
    padding: 10px;
    border-radius: 5px;
    outline: none;
}
.all_fields p{
    margin-bottom: 5px;
    font-weight: 600;
}
.photo_upload{
    width: max-content;
    margin-top: 10px;
    border: 1px solid rgb(10, 63, 116);
    padding: 20px;
}